import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import CardHeading from "../../components/CardHeading";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css"; // You might need to import the default styles.
import ConvertImagesToPdf from "../../helpers/ConvertImagesToPdf/ConvertImagesToPdf";
import ConvertMultiplePagePDFtoPDF from "../../helpers/ConvertMultiplePagePDFtoPDF/ConvertMultiplePagePDFtoPDF";
import MultiplePageUpload from "../../components/MultiplePageUpload";
import LoadingButton from "../../components/LoadingButton/LoadingButtons";

function AddTrainingForm({ onSubmit }) {
  const active_location = useSelector((state) => state.location); // get user info
  const active_company = useSelector((state) => state.company); // get user info
  const active_region = useSelector((state) => state.region); // get user info
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const [date, setDate] = useState(moment().format("MM-DD-YYYY"));

  // const [time, setTime] = useState(moment().format("h:mm:ss A"));
  // need this time in a unixtimestamp intstead of the current format for both date and time
  const [date, setDate] = useState(moment());
  const [time, setTime] = useState(moment());

  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    register("training_date", { required: true });
    register("training_time", { required: true });

    // Set default values
    setValue("training_date", date);
    setValue("training_time", time);
  }, [register, setValue, date, time]);

  const handleFilesChange = (files) => {
    setUploadedFiles(files);
  };

  const handleFormSubmit = async (data) => {
    let files = uploadedFiles.filter((file) => file !== null);
    // when creating files I also
    data.files = files;
    let type = data.type || data.files[0].type || "image"; // Default to 'image' if type is not specified

    if (files.length > 0 && type !== "application/pdf") {
      let file = await ConvertImagesToPdf(files, type, "training.pdf");

      // Ensure file is a Blob
      if (file instanceof Blob) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64data = e.target.result;
          data.file_upload = base64data;
          onSubmit(data, active_location, active_region, active_company); // Pass the base64 string instead of the file object
        };
        reader.readAsDataURL(file);
      } else {
        console.error("Converted file is not a Blob:", file);
      }
    } else if (files.length > 0 && type === "application/pdf") {
      let filePDF = await ConvertMultiplePagePDFtoPDF(files);

      // Log to check if the filePDF is a Blob
      console.log("Generated PDF Blob:", filePDF);

      const reader = new FileReader();
      reader.onload = (e) => {
        const base64data = e.target.result;
        data.file_upload = base64data;
        onSubmit(data, active_location, active_region, active_company);
      };
      reader.readAsDataURL(filePDF);
    } else {
      onSubmit(data); // Call onSubmit with the existing data if no file is uploaded
    }
  };

  return (
    <>
      <CardHeading title="Add Training Record" />
      <p className="notes">
        Note: If the user already exists, changes to their information on this
        screen will not be reflected. To edit a trainee, visit the Trainees List
        page.
      </p>
      <form>
        <div className="row">
          <div
            className={`half-width ${errors["first_name"] ? "has-error" : ""}`}
          >
            <label htmlFor="first_name">First Name *</label>
            <input
              type="text"
              name="first_name"
              placeholder={`First Name`}
              {...register("first_name", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
            />
          </div>
          <div
            className={`half-width ${errors["last_name"] ? "has-error" : ""}`}
          >
            <label htmlFor="last_name">Last Name *</label>
            <input
              type="text"
              name="last_name"
              placeholder={`Last Name`}
              {...register("last_name", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
            />
          </div>
        </div>

        <div className="row">
          <div className={`half-width`}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              placeholder={`Email`}
              {...register("email", {
                required: false,
                minLength: 3,
                maxLength: 65,
              })}
            />
          </div>

          <div className={`half-width ${errors["phone"] ? "has-error" : ""}`}>
            <label htmlFor="phone">Phone *</label>
            <input
              type="phone"
              name="phone"
              placeholder={`Phone`}
              {...register("phone", {
                required: true,
                minLength: 10,
                maxLength: 18,
              })}
            />
          </div>
        </div>

        <div className="row">
          <div className={`half-width ${errors["company"] ? "has-error" : ""}`}>
            <label htmlFor="company">Company *</label>
            <input
              type="text"
              name="company"
              placeholder={`Company`}
              {...register("company", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
            />
          </div>
          <div
            className={`half-width ${
              errors["visitor_type"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="visitor_type">Visitor Type *</label>
            <select
              name="visitor_type"
              {...register("visitor_type", { required: true })}
            >
              <option value="">Please Select</option>
              <option value="truck">Truck Driver</option>
              <option value="contractor">Contractor / Vendor</option>
              <option value="guest">Guest</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className={`half-width`}>
            <label htmlFor="msha_number">MSHA Number</label>
            <input
              type="text"
              name="msha_number"
              placeholder={`MSHA Number`}
              {...register("msha_number", {
                required: false,
                minLength: 3,
                maxLength: 25,
              })}
            />
          </div>
          <div className={`half-width`}>
            <label htmlFor="truck_number">Truck Number</label>
            <input
              type="text"
              name="truck_number"
              placeholder={`Truck Number`}
              {...register("truck_number", {
                required: false,
                minLength: 3,
                maxLength: 25,
              })}
            />
          </div>
        </div>

        <div className="row">
          <div
            className={`half-width ${
              errors["preferred_language"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="preferred_language">Preferred Language</label>
            <select
              name="preferred_language"
              {...register("preferred_language", { required: false })}
            >
              <option value="">Please Select</option>
              <option value="en">English</option>
              <option value="es">Spanish</option>
            </select>
          </div>
          <div
            className={`quarter-width ${
              errors["training_date"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="training_date">Training Date *</label>
            <Datetime
              value={date}
              inputProps={{ name: "training_date", placeholder: "Select Date" }}
              onChange={(date) => {
                const formattedDate = date.format("MM-DD-YYYY");
                console.log(formattedDate);
                setDate(formattedDate);
                setValue("training_date", formattedDate);
              }}
              closeOnSelect
              timeFormat={false}
            />
          </div>
          <div
            className={`quarter-width ${
              errors["training_time"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="training_time">Training Time *</label>
            <Datetime
              value={time}
              inputProps={{ name: "training_time", placeholder: "Select Time" }}
              onChange={(time) => {
                const formattedTime = time.format("h:mm:ss A");
                console.log(formattedTime);
                setTime(formattedTime);
                setValue("training_time", formattedTime);
              }}
              closeOnSelect
              dateFormat={false}
              timeFormat="h:mm:ss A"
            />
          </div>
        </div>

        <div className="row">
          <div
            className={`full-width ${errors["file_upload"] ? "has-error" : ""}`}
          >
            <label htmlFor="file_upload" className="file-upload-label">
              Upload File <span>(pdf, png, or jpg)</span> *{" "}
            </label>
            <MultiplePageUpload onFilesChange={handleFilesChange} />
            {errors.file_upload && (
              <p className="error-message">File upload is required</p>
            )}
          </div>
        </div>

        <div className="form-bottom-button-wrapper">
          <LoadingButton
            className="button button-blue"
            onClick={handleSubmit(handleFormSubmit)}
            staticPlaceholder={"Add Record"}
          ></LoadingButton>
        </div>
      </form>
    </>
  );
}

export default AddTrainingForm;
